const changelanguages = (lng) => {
  let l
  switch (lng) {
    case 'zh-CN':
      l = 'zh-hans'
      break
    case 'zh-TW':
      l = 'zh-hant'
      break
    default:
      l = 'en'
      break
  }

  return l
}

export default changelanguages
