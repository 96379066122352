import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled((props) => <Link {...props} />)`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  margin: ${(props) => (props.margin ? props.margin : '0 0 12px 0')};
  text-align: left;
  &:hover {
    span {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledLinkLanguage = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  margin: ${(props) => (props.margin ? props.margin : '0 0 12px 0')};
  text-align: left;
  &:hover {
    span {
      cursor: pointer;
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const LinkRow = ({
  link = { },
  children,
  gatsbyLink,
  languageLink,
  ...restProps
}) => {
  const { href = '/', sameTab = false } = link

  if (gatsbyLink) {
    return (

      <StyledLink
        {...restProps}
        activeStyle={{ color: '#E20613' }}
        partiallyActive={true}
        to={`${link}`}>
        {children}
      </StyledLink>
    )
  }

  if (languageLink) {
    return (

      <StyledLinkLanguage
        {...restProps}
        activeStyle={{ color: '#E20613' }}
        partiallyActive={true}>
        {children}
      </StyledLinkLanguage>
    )
  }

  return sameTab ? (
    <a style={{ marginTop: '8px' }} href={href} {...restProps}>
      <div>
        {children}
      </div>
    </a>
  ) : (
    <a href={href} target="_blank" rel="noopener noreferrer" {...restProps}>
      <div>
        {children}
      </div>
    </a>
  )
}

export default LinkRow
