import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import gorditaBold from '../assets/fonts/gordita-bold.woff2'
import gorditaMedium from '../assets/fonts/gordita-medium.woff2'
import gorditaRegular from '../assets/fonts/gordita-regular.woff2'
import gorditaBlack from '../assets/fonts/gordita-black.woff2'

export const theme = {
  colors: {
    primary: '#E20613',
    secondary: '#7B7B7B',
    white: '#ffffff',
    darkBlue: '#141840',
    whiteSmoke: '#FAFBFC',
  },
  font: {
    gordita: "'gorditaregular', sans-serif",
    gorditaM: "'gorditamedium', sans-serif",
    gorditaB: "'gorditabold', sans-serif",
    gorditaBL: "'gorditablack', sans-serif",
    manrope: "'Manrope'",
  },
  fontSize: {
    xs: '0.75rem', // 12px
    xss: '0.8125rem', // 13px
    sm: '0.875rem', // 14px
    base: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '2xll': '1.625rem', // 26px
    '3xl': '1.75rem', // 28px
    '4xl': '2.125rem', // 34px
    '5xl': '3rem', // 48px
  },
  fontWeight: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  lineHeight: {
    none: 'none',
    xs: '0.75rem', // 12px
    xss: '0.8125rem', // 13px
    sm: '0.875rem', // 14px
    base: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.75rem', // 28px
    '4xl': '2.125rem', // 34px
    '4xll': '2.375rem', // 38px
    '5xl': '2.625rem', // 42px
    '6xl': '3rem', // 48px
  },
}

export const GlobalStyle = createGlobalStyle`
  ${reset}
  @import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.3.15/slick.css')
  @import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css')
  *, *:before, *:after {
    box-sizing: border-box;
    font-display: fallback !important;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 16px;
  }
  body {
    overflow-x: hidden;

  }
  /* scrolling */
  body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  }

  body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  @font-face {
    font-family: gorditablack;
    src: url(${gorditaBlack}) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: fallback !important;
  }
  @font-face {
    font-family: gorditabold;
    src: url(${gorditaBold}) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: fallback !important;
  }
  @font-face {
    font-family: gorditamedium;
    src: url(${gorditaMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: fallback !important;
  }
  @font-face {
    font-family: gorditaregular;
    src: url(${gorditaRegular}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: fallback !important;
  }

  .separator {
    width: 100%;
    max-width: 1440px;
    height: 1px;
    background-color: #E6E6E6;
    margin: 20px auto;
  }
  .overlay {
    overflow: hidden;
  }

  .rich-text {
    h1, h2, h3, h4 {
      font-family: ${theme.font.manrope};
      font-weight: 300;
    }
    p {
      color: ${theme.colors.secondary};
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      font-family: ${theme.font.manrope};
      margin-bottom: 1rem;
    }
    ul {
      padding-left: 40px;
      margin: 20px 0;
      li {
        position: relative;
        color: ${theme.colors.secondary};
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        font-family: ${theme.font.manrope};
        &:before {
          position: absolute;
          content: '';
          top: 10px;
          left: -12px;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: ${theme.colors.primary};
          
        }
      }
    }
    .wp-block-quote {
      margin: 20px 0 20px 40px;
      padding-left: 20px;
      border-left: 1px solid #7B7B7B;
      p {
        color: ${theme.colors.primary};
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        font-family: ${theme.font.manrope};
      }
      cite {
        color: ${theme.colors.secondary};
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
        font-family: ${theme.font.manrope};
      }
    }

    
    a {
      color: ${theme.colors.primary};
    }
  }
  .anchor-footer {
    margin-bottom: 12px;
  }

  .slick-slide {
    outline: none !important;
  }
  .slick-track {
    display: flex !important;
  }
`
