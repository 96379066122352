import _ from 'lodash'
import moment from 'moment'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest, changelanguages } from '../helpers'

const NewsroomContext = createContext('')

const NewsroomProvider = ({ children }) => {
  const { i18n } = useTranslation()

  const [totalNews, setTotalNews] = useState(0)
  const [lastNews, setLastNews] = useState([])
  const [news, setAllNews] = useState([])
  const [loading, setLoading] = useState(false)
  const [years, setYears] = useState([])

  const getYears = () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const params = {
      lang: 'en',
    }
    makeRequest({
      headers,
      endPoint: 'post-overview',
      per_page: 1000,
      custom: true,
      params,
    }).then((resp) => {
      setYears(_.map(resp.data.all_years, (y) => ({
        value: y,
        label: y,
      })))
    })
  }

  const getNews = async (page, year) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    let params = {
      lang: changelanguages(i18n.language),
      per_page: 10,
      page,
      // page,
    }
    if (!_.isEmpty(year)) {
      params = {
        ...params,
        after: `${year}-01-01T00:00:00`,
        before: `${year}-12-31T00:00:00`,
      }
    }
    return makeRequest({
      headers,
      endPoint: 'posts',
      params,
    })
  }

  const getAllNews = async (page, year) => {
    setLoading(true)
    const headers = {
      'Content-Type': 'application/json',
    }
    let params = {
      lang: changelanguages(i18n.language),
      per_page: 10,
      page,
      // page,
    }
    if (!_.isEmpty(year)) {
      params = {
        ...params,
        after: `${year}-01-01T00:00:00`,
        before: `${year}-12-31T00:00:00`,
      }
    }
    makeRequest({
      headers,
      endPoint: 'posts',
      params,
    }).then((resp) => {
      setLastNews(resp.data.slice(0, 2))
      setTotalNews(resp.total_post)
      setAllNews(resp.data)
      getYears(resp.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    getAllNews(1)
  }, [i18n.language])

  const values = {
    loading,
    news,
    lastNews,
    years,
    getNews,
    totalNews,
  }

  return (
    <NewsroomContext.Provider value={values}>
      {children}
    </NewsroomContext.Provider>
  )
}

const useNewsroom = () => {
  const context = useContext(NewsroomContext)

  if (!context) { throw new Error('useLocale must be used within an Newsroom Provider') }

  return context
}

export { NewsroomProvider, useNewsroom }
