import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  &:hover p {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export default StyledLink
