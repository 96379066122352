import _ from 'lodash'
import { Link } from 'gatsby'
import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import Wrapper from '../page-elements/wrapper'
import Accordion from '../accordion/accordion'
import { menuMobile } from '../../data/data'
import { PreFooter } from './components'
import { useNewsroom } from '../../hooks/use-news'
import { useOffices } from '../../hooks/use-offices'
import { usePartners } from '../../hooks/use-partners'
import Spinner from '../spinner/spinner'
import SocialMedia from '../social-media'
import { getHeadquarter } from '../../helpers/sort-by-country'

const TextLink = styled.p`
  color: #6D6D6D;
  font-family: Manrope;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  a {
    margin: 5px 5px;
    color: #6D6D6D;
    font-family: Manrope;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    display: inline-block
  }
  a:first-child {
    margin-left: 0;
  }

`

const FooterMobile = ({ socialMedia, t, i18n }) => {
  const [dataFlow, setDataFlow] = useState([])
  const [headquarter, setHeadquarter] = useState({})
  const { lastNews, loading } = useNewsroom()
  const { partherOnFooter } = usePartners()
  const { offices } = useOffices()
  const objs = _.reduce(socialMedia, (result, value, key) => {
    (result).push({
      name: key,
      link: value,
      size: 22,
      color: '#E20613',
    })

    return result
  }, [])

  useEffect(() => {
    setHeadquarter(getHeadquarter(offices))
  }, [offices])

  useEffect(() => {
    const newData = _.map(menuMobile, (item) => {
      if (item.src === '/our-clients') {
        return {
          ...item,
          children: partherOnFooter,
        }
      }
      if (item.src === '/connect-with-us') {
        return {
          ...item,
          children: [
            {
              title: 'footer.us.headquarter',
              text: _.get(headquarter, 'acf.office_section.full_address'),
              noHover: true,
            },
            {
              title: 'footer.us.email',
              text: _.get(headquarter, 'acf.office_section.email'),
              link: {
                href: `mailto:${_.get(headquarter, 'acf.office_section.email')}`,
                sameTab: true,
              },
            },
            {
              title: 'footer.us.phone',
              text: _.get(headquarter, 'acf.office_section.phone_no'),
              link: {
                href: `tel:${_.get(headquarter, 'acf.office_section.phone_no')}`,
                sameTab: true,
              },
            },
          ],
        }
      }
      return item
    })
    setDataFlow(newData)
  }, [partherOnFooter, headquarter, i18n.language])

  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <Wrapper
      displayM="flex"
      displayD="none"
    >
      <PreFooter />
      <Wrapper
      >
        <Accordion
          data={dataFlow}
          extradata={lastNews}
          t={t}
          i18n={i18n}
        />
        <Wrapper
          paddingM="0 10px"
          widthD="1240px"
          alignItemsM="flex-start">
          <SocialMedia data={objs } />
        </Wrapper>
        <Wrapper
          justifyContentM="flex-start"
          alignItemsM="flex-start"
          paddingM="0 25px 20px"
        >
          <Wrapper
            style={{
              borderBottom: '1px solid #E6E6E6',
              padding: '0 16px',
              margin: '10px 0',
              width: 'calc(100% - 32px)',
            }}
          >

          </Wrapper>
          <TextLink>
            {t('footer.copyright')}
          </TextLink>
          <TextLink>
            <Link to="/terms-conditions/">
              {t('footer.terms')}
            </Link>
              |
            <Link to="/privacy-policy/">
              {t('footer.privacy')}
            </Link>
          </TextLink>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default FooterMobile
