import _ from 'lodash'
import React, { useState, useRef } from 'react'
import styled, { withTheme } from 'styled-components'
import { navigate } from 'gatsby'
import Dropdown from 'react-dropdown'
import Logo from '../../assets/icons/TAM-logo-secondary.svg'
import LogoIN from '../../assets/icons/TAM-icon-linkedin.svg'
import Wrapper from '../page-elements/wrapper'
import Button from '../button'
import './style.scss'
import LinkRow from '../LinkRow'
import DropdownMenu from '../dropdown'
import DropdownLanguage from '../dropdown-language'
import 'react-dropdown/style.css'

import IconDropdown from '../../assets/icons/TAM-icon-dropdown.svg'

export const LinkSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-family: ${({ theme }) => theme.font.manrope};
  font-weight: 600;
`

const Header = (props) => {
  const { theme, t, i18n } = props
  const options = [
    { value: 'en', label: 'EN' },
    { value: 'zh-TW', label: '繁體' },
    { value: 'zh-CN', label: '简体' },
  ]
  const [defaultValue, setDefaultValue] = useState(_.find(options, { value: i18n.language }) || options[0])

  const goTo = (e) => {
    e.preventDefault()

    navigate('/connect-with-us')
  }
  const optMenu = [
    { value: '/what-we-offer/cargo', label: t('home.cargo') },
    { value: '/what-we-offer/passenger', label: t('home.passenger') },
  ]

  const changeLanguageHandler = (lang) => {
    setDefaultValue(lang)
    i18n.changeLanguage(lang.value)
  }

  return (
    <Wrapper
      displayM="none"
      displayD="flex"
      style={{
        zIndex: 1,
      }}
    >
      <Wrapper
        widthD="1240px"
        justifyContentD="space-between"
        heightM="100px"
        paddingD="0 10px"
      >
        <Wrapper
          widthD="74px"
          widthM="75px"
          marginD="0"

        >
          <LinkRow margin="0" gatsbyLink link="/">
            <Logo width="75" fill={_.get(theme, 'colors.primary')}/>
          </LinkRow >
        </Wrapper>
        <Wrapper
          widthD="calc(100% - 100px)"
          justifyContentM="flex-end"
          marginD="0"
        >
          <ul className="menu" >
            <li>
              <LinkRow margin="0" gatsbyLink link="/who-we-are/">
                <LinkSpan style= {{
                  textTransform: 'capitalize',
                }}>{t('menu.are')}</LinkSpan>
              </LinkRow >
            </li>
            <li>
              <DropdownMenu
                title={t('menu.offer')}
                options={optMenu}
              />
            </li>
            <li>
              <LinkRow margin="0" gatsbyLink link="/our-clients/">
                <LinkSpan style= {{
                  textTransform: 'capitalize',
                }}>{t('menu.server')}</LinkSpan>
              </LinkRow >
            </li>
            {/* <li>
              <DropdownLanguage
                title={defaultValue.label}
                options={options}
                changeLanguageHandler={changeLanguageHandler}
              />
            </li> */}
            {/* <li>
              <Dropdown
                ref={dropdown}
                arrowClosed={<span style={{ display: 'inline-block' }}><IconDropdown width={18} color="#000" /></span> }
                arrowOpen={<span style={{
                  display: 'inline-block',
                  transform: 'rotate(180deg)',
                }}><IconDropdown width={18} color="#000" /></span>}
                options={options}
                controlClassName='control-language-dropdown'
                placeholderClassName='placeholder-language-dropdown'
                menuClassName='menu-language-dropdown'
                onChange={changeLanguageHandler}
                value={defaultValue}
                placeholder="Select an option" />
            </li> */}
            <li>
              <a href={'https://hk.linkedin.com/company/tamwingkun'} target="_blank" rel="noreferrer">
                <LogoIN color="#7B7B7B" width="30" />
              </a>
            </li>
          </ul>
          <Button
            onClick={goTo}
            active
            style= {{
              textTransform: 'capitalize',
              marginLeft: '20px',
            }}
            widthD="171px"
            widthM="171px">
            {t('menu.us')}
          </Button>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default withTheme(Header)
