import _ from 'lodash'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest, changelanguages } from '../helpers'

const PartnersContext = createContext('')

const PartherProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [partners, setPartners] = useState([])
  const [partnersHome, setPartnersHome] = useState([])
  const [partnersShowCase, setPartnersShowCase] = useState([])
  const [partherOnFooter, setPartherOnFooter] = useState([])
  const [loading, setLoading] = useState(false)

  const getPartnersHome = (arrayPartners) => {
    const partnersAtHome = []
    _.map(arrayPartners, (partner) => {
      if (_.get(partner, 'acf.is_visible_on_home_page')) {
        partnersAtHome.push(partner)
      }
    })
    return _.orderBy(partnersAtHome, ['slug'],
      ['asc'])
  }

  const getPartnersHasDetail = (arrayPartners) => {
    const partnersWithDetails = []
    _.map(arrayPartners, (partner) => {
      if (_.get(partner, 'acf.has_detail_page')) {
        partnersWithDetails.push(partner)
      }
    })
    const orderPartner = _.orderBy(partnersWithDetails, ['date'],
      ['asc'])

    setPartnersShowCase(orderPartner)
    return orderPartner
  }

  const getPartners = useCallback(async () => {
    setLoading(true)
    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'partners',
      params: {
        lang: changelanguages(i18n.language),
        per_page: 100,
      },
    }).then((resp) => {
      setPartners(_.orderBy(resp.data, ['slug'],
        ['asc']))
      const atHome = getPartnersHome(resp.data)
      getPartnersHasDetail(resp.data)
      setPartnersHome(atHome)
      setLoading(false)
    })
  }, [i18n.language])

  useEffect(() => {
    const showcasePathers = _.map(partnersShowCase, (partner) => ({
      text: partner.title.rendered,
      src: `/our-clients/${partner.slug}`,
    }))

    setPartherOnFooter(_.sortBy(showcasePathers, 'text'))
  }, [partnersShowCase, i18n.language])

  useEffect(() => {
    getPartners()
  }, [i18n.language])

  useEffect(() => {
    getPartners()
  }, [])

  const values = {
    loading,
    partners,
    partnersHome,
    partnersShowCase,
    partherOnFooter,
    getPartnersHasDetail,
  }
  return (
    <PartnersContext.Provider value={values}>
      {children}
    </PartnersContext.Provider>
  )
}

const usePartners = () => {
  const context = useContext(PartnersContext)

  if (!context) { throw new Error('useLocale must be used within an CategoriesProvider') }

  return context
}

export { PartherProvider, usePartners }
