/* eslint-disable max-len */

const menu = [
  {
    title: 'menuMobile.are',
    src: '/who-we-are/',
    children: [
      {
        text: 'menuMobile.team',
        src: '/who-we-are/#team',
        anchor: true,
      },
      {
        text: 'menuMobile.mission',
        src: '/who-we-are/#mission',
        anchor: true,
      },
      {
        text: 'menuMobile.company',
        src: '/who-we-are/#company',
        anchor: true,
      },
      {
        text: 'menuMobile.network',
        src: '/who-we-are/#network',
        anchor: true,
      },
      {
        text: 'menuMobile.milestone',
        src: '/who-we-are/#milestones',
        anchor: true,
      },
      {
        text: 'menuMobile.download',
        src: '/who-we-are/#downloadable',
        anchor: true,
      },
    ],
  },
  {
    title: 'menuMobile.offer',
    src: '/what-we-offer/',
    children: [
      {
        text: 'menuMobile.cargo',
        src: '/what-we-offer/cargo/',
        noAirline: true,
      },
      {
        text: 'menuMobile.passenger',
        src: '/what-we-offer/passenger/',
        noAirline: true,
      },
    ],
  },
  {
    title: 'menuMobile.server',
    src: '/our-clients/',
  },
  {
    title: 'menuMobile.newsroom',
    src: '/newsroom/',
  },
  {
    title: 'menuMobile.us',
    src: '/connect-with-us/',
  },
]

const menuMobile = [
  {
    title: 'menuMobile.are',
    src: '/who-we-are',
    input: 'are',
    children: [
      {
        title: 'menuMobile.team',
        src: '/who-we-are/#team',
        input: 'team',
      },
      {
        title: 'menuMobile.mission',
        src: '/who-we-are/#mission',
        input: 'mission',
      },
      {
        title: 'menuMobile.company',
        src: '/who-we-are/#company',
        input: 'company',
      },
      {
        title: 'menuMobile.network',
        src: '/who-we-are/#network',
        input: 'network',
      },
      {
        title: 'menuMobile.milestone',
        src: '/who-we-are/#milestonesmobile',
        input: 'milestone',
      },
      {
        title: 'menuMobile.download',
        src: '/who-we-are/#downloadable',
        input: 'download',
      },
    ],
  },
  {
    title: 'What We Offer',
    src: '/what-we-offer',
    input: 'offer',
    children: [
      {
        title: 'menuMobile.cargo',
        src: '/what-we-offer/cargo',
        input: 'cargo',
      },
      {
        title: 'menuMobile.passenger',
        src: '/what-we-offer/passenger',
        input: 'passenger',
      },
    ],
  },
  {
    title: 'Who We Serve',
    src: '/our-clients',
    input: 'server',
  },
  {
    title: 'Newsroom',
    src: '/newsroom',
    input: 'newsroom',
  },
  {
    title: 'Work With Us',
    src: '/connect-with-us',
    isOpenByDefault: true,
    input: 'us',
  },
]

export {
  menu,
  menuMobile,

}
