import React from 'react'
import styled from 'styled-components'
import { device } from '../../theme/device'

const CircleContainer = styled.div`
  overflow: hidden;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 540px;
  z-index: -1;
  &:before {
    left: -100px;
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #E20613;
    width: 600px;
    height: 600px;
    content: '';
    z-index: -1;
  }
  @media (min-width: 768px) {
    &:before {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: inset 0 0 0 1px #E20613;
      width: 1120px;
      height: 1120px;
      content: '';
      z-index: -1;
    }
  }
  @media ${device.tablet} {
    &:before {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: inset 0 0 0 1px #E20613;
      width: 1120px;
      height: 1120px;
      content: '';
      z-index: -1;
    }
  }
  @media ${device.desktop} {
    &:before {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: inset 0 0 0 1px #E20613;
      width: 1920px;
      height: 1920px;
      content: '';
      z-index: -1;
    }
  }
`
const Cover = styled.div`
  position: absolute;
  width: 100%;
  height: 540px;
  background: rgb(254,254,254);
  transform: translateY(-43px) rotate(15deg);
  background: rgb(254,254,254);
  background: linear-gradient(94deg,rgba(254,254,254,0) 36%,rgb(255 255 255) 68%);

  @media ${device.tablet} {
    background: linear-gradient(135deg, rgba(254,254,254,0) 40%, rgba(255,255,255,1) 68%);
    transform: none;
  }

  @media ${device.desktop} {
    background: linear-gradient(135deg, rgba(254,254,254,0) 40%, rgba(255,255,255,1) 68%);
    transform: none;
  }
`

const Circle = (props) => (
  <CircleContainer {...props}>
    <Cover />
  </CircleContainer>
)

export default Circle
