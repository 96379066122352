/* eslint-disable quote-props */
import _ from 'lodash'
/* eslint-disable no-nested-ternary */

function groupBy(arr, criteria) {
  const newObj = arr.reduce((acc, currentValue) => {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = []
    }

    acc[currentValue[criteria]].push(currentValue)
    return acc
  }, {})
  return newObj
}

export function getContinents(offices) {
  return _.uniq(_.map(offices, 'continent.slug'))
}

function getTitle(obj) {
  const country = _.get(obj, 'continent.country.name')
  const city = _.get(obj, 'continent.country.city.name')

  if (_.get(obj, 'continent.country.city.slug') === 'hong-kong-sar') return city

  return `${country}`
}

export function getHeadquarter(o) {
  return _.head(_.filter(o, (x) => !!_.includes(_.get(x, 'acf.office_section.type'), 'headquarter')))
}

// RJ 25/01/22 temporaly fix after change of the client
const organizeMainOffice = (office, isHeadquarter, offices) => ({
  id: _.get(office, 'id'),
  lat: _.toNumber(_.get(office, 'acf.office_section.geographic_coordinate.latitude')),
  lng: _.toNumber(_.get(office, 'acf.office_section.geographic_coordinate.longitude')),
  number: 1,
  country: _.get(office, 'continent.country.name'),
  countrySlug: _.get(office, 'continent.country.slug'),
  title: getTitle(office),
  type: _.get(office, 'type'),
  offices,
  isHeadquarter: !_.isEmpty(isHeadquarter),
  img: _.get(office, 'continent.country.city.featured_image.url'),
  alt: _.get(office, 'continent.country.city.featured_image.alt'),
  description: _.get(office, 'continent.country.description'),
  continent: _.get(office, 'continent.slug'),
  contact_person: _.get(office, 'acf.office_section.contact_person'),
  email: _.get(office, 'acf.office_section.email'),
})

export function sortByCities(offices) {
  const group = groupBy(offices, 'country')
  const g = _.sortBy(_.map(group, (o) => {
    const isHeadquarter = _.includes(_.map(o, (x) => _.get(x, 'acf.office_section.headquarter')), true)
    const cordinatesHeadquarter = _.get(_.head(isHeadquarter), 'acf.office_section.geographic_coordinate')
    const officesOrderBy = _.orderBy(o, ['type', 'office_order_no'], ['desc', 'asc'])
    return {
      id: !_.isEmpty(isHeadquarter) ? _.head(isHeadquarter).id : o[0].id,
      lat: !_.isEmpty(isHeadquarter) ? _.toNumber(cordinatesHeadquarter.latitude) : _.toNumber(_.get(o[0], 'acf.office_section.geographic_coordinate.latitude')),
      lng: !_.isEmpty(isHeadquarter) ? _.toNumber(cordinatesHeadquarter.longitude) : _.toNumber(_.get(o[0], 'acf.office_section.geographic_coordinate.longitude')),
      number: o.length,
      mainOffice: organizeMainOffice(_.head(_.filter(o, (off) => _.get(off, 'acf.office_section.show_on_map'))), isHeadquarter, officesOrderBy),
      order_no: _.toNumber(o[-0].order_no),
      order_offcie_no: _.toNumber(o[-0].order_offcie_no),
      offices: officesOrderBy,
      country: _.get(o[0], 'continent.country.name'),
      title: getTitle(o[0]),
      isHeadquarter,
      img: _.head(_.map(o, (x) => _.get(x, 'continent.country.city.featured_image.url'))),
      alt: _.head(_.map(o, (x) => _.get(x, '[21].continent.country.city.featured_image.alt'))),
      description: _.head(_.map(o, (x) => _.get(x, 'continent.country.description'))),
      continent: _.head(_.map(o, (x) => _.get(x, 'continent.slug'))),
      continent_name: _.head(_.map(o, (x) => _.get(x, 'continent.name'))),
    }
  }), ['order_no', 'order_offcie_no'], ['desc', 'desc'])
  return g.sort((a, b) => b.isHeadquarter - a.isHeadquarter)
}
