import React from 'react'
import styled from 'styled-components'

export const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.font.manrope};
  line-height: ${({ theme }) => theme.lineHeight.sm};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.colors.secondary};
  text-align: left;
  margin: 23px 0 8px;
  &:first-child { 
    margin-top: 0;
  }
`

const LinkSubtitle = ({ text }) => (
  <Subtitle>
    {text}
  </Subtitle>
)

export default LinkSubtitle
