import styled from 'styled-components'
import { device } from '../../theme/device'

export const H2 = styled.h2`
  font-size: ${(props) => (props.fontSize ? props.theme.fontSize[props.fontSize] : props.theme.fontSize['2xl'])};
  font-family: ${(props) => (props.font ? props.theme.font[props.font] : props.theme.font.manrope)}};
  line-height: ${(props) => (props.lineHeight ? props.theme.lineHeight[props.lineHeight] : props.theme.lineHeight['3xl'])};
  font-weight: ${(props) => (props.fontWeightM ? props.theme.fontWeight[props.fontWeightM] : props.theme.fontWeight.light)};
  color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.secondary)};
  @media ${device.desktop} {
    font-size: ${(props) => (props.fontSizeD ? props.theme.fontSize[props.fontSizeD] : props.theme.fontSize['3xl'])};
    line-height: ${(props) => (props.lineHeightD ? props.theme.lineHeight[props.lineHeightD] : props.theme.lineHeight['4xl'])};
  }
`
