import React from 'react'
import { WaveLoader } from 'react-loaders-kit'
import { Wrapper } from '../page-elements'

const Spinner = ({ loading, height }) => {
  const loaderProps = {
    loading,
    size: 100,
    duration: 1,
    color: '#E20613',
  }
  return (
    <Wrapper
      heightM={height || 'calc(100vh - 100px)'}
    >
      <WaveLoader {...loaderProps} />
    </Wrapper>
  )
}

export default Spinner
