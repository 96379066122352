import _ from 'lodash'
import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { withTrans } from '../../../../i18n/withTrans'
import Wrapper from '../../../page-elements/wrapper'
import { useWindowDimensions } from '../../../../helpers'
import ButtonWork from '../../../button-work'
import { H1 } from '../../../page-elements/h1'
import Circle from '../../../circle/circle'
import { device } from '../../../../theme/device'

import './pre-footer.scss'

const PreFooterWrapper = styled((props) => <Wrapper {...props} />)` 
  .title-box {
    text-align: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    .button-box {
      max-width: 250px; 
    }
    .title-box {
      text-align: left;
    }
  }
  @media ${device.desktop} {
    .title-box {
      text-align: left;
    }
  }
`

const PreFooter = ({ t }) => {
  const goTo = (e) => {
    e.preventDefault()
    // TODO: do something with form values
    navigate('/connect-with-us')
  }
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  return (
    <div className="pre-fotter-container">
      <div className="pre-fotter-box">
        <Circle />
        <PreFooterWrapper
          widthD="670px"
          flexDirectionD="row">
          <Wrapper
            className="title-box"
            widthM="280px"
            widthD="480px"
          >
            <H1>{t('footer.preFooter.title')}</H1>
          </Wrapper>
          <Wrapper
            className="button-box"
            marginM="32px 0 0 0"
            widthD="220px"
            justifyContentD="flex-end"

          >
            <ButtonWork onPress={goTo}>{t('footer.preFooter.button')}</ButtonWork>
          </Wrapper>
        </PreFooterWrapper>
      </div>
    </div>

  )
}

export default withTrans(PreFooter)
