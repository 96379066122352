import styled from 'styled-components'
import { device } from '../../theme/device'

export const P = styled.p`
  line-height: ${(props) => (props.lineHeight ? props.theme.lineHeight[props.lineHeight] : props.theme.lineHeight.xl)}};
  font-family: ${(props) => (props.font ? props.theme.font[props.font] : props.theme.font.manrope)}};
  color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.secondary)};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  font-weight: ${(props) => (props.fontWeightM ? props.theme.fontWeight[props.fontWeightM] : props.theme.fontWeight.normal)};
  font-size: ${(props) => (props.fontSizeM ? props.theme.fontSize[props.fontSizeM] : props.theme.fontSize.base)}};
  @media ${device.desktop} {
    font-size: ${(props) => (props.fontSizeD ? props.theme.fontSize[props.fontSizeD] : props.theme.fontSize[props.fontSizeM])};
    padding: ${(props) => (props.paddingD ? props.paddingD : 0)};
    margin: ${(props) => (props.marginD ? props.marginD : 0)};
    line-height: ${(props) => (props.lineHeightD ? props.theme.lineHeight[props.lineHeightD] : props.theme.lineHeight.xl)}};
  }
`
