// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-connect-with-us-js": () => import("./../../../src/pages/connect-with-us.js" /* webpackChunkName: "component---src-pages-connect-with-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsroom-[slug]-js": () => import("./../../../src/pages/newsroom/[slug].js" /* webpackChunkName: "component---src-pages-newsroom-[slug]-js" */),
  "component---src-pages-newsroom-componets-card-related-js": () => import("./../../../src/pages/newsroom/componets/card-related.js" /* webpackChunkName: "component---src-pages-newsroom-componets-card-related-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-our-clients-[slug]-js": () => import("./../../../src/pages/our-clients/[slug].js" /* webpackChunkName: "component---src-pages-our-clients-[slug]-js" */),
  "component---src-pages-our-clients-components-block-image-js": () => import("./../../../src/pages/our-clients/components/block-image.js" /* webpackChunkName: "component---src-pages-our-clients-components-block-image-js" */),
  "component---src-pages-our-clients-components-block-info-js": () => import("./../../../src/pages/our-clients/components/block-info.js" /* webpackChunkName: "component---src-pages-our-clients-components-block-info-js" */),
  "component---src-pages-our-clients-components-block-type-js": () => import("./../../../src/pages/our-clients/components/block-type.js" /* webpackChunkName: "component---src-pages-our-clients-components-block-type-js" */),
  "component---src-pages-our-clients-components-sticky-navbar-index-js": () => import("./../../../src/pages/our-clients/components/sticky-navbar/index.js" /* webpackChunkName: "component---src-pages-our-clients-components-sticky-navbar-index-js" */),
  "component---src-pages-our-clients-components-video-js": () => import("./../../../src/pages/our-clients/components/video.js" /* webpackChunkName: "component---src-pages-our-clients-components-video-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-what-we-offer-[slug]-js": () => import("./../../../src/pages/what-we-offer/[slug].js" /* webpackChunkName: "component---src-pages-what-we-offer-[slug]-js" */),
  "component---src-pages-what-we-offer-components-box-info-js": () => import("./../../../src/pages/what-we-offer/components/box-info.js" /* webpackChunkName: "component---src-pages-what-we-offer-components-box-info-js" */),
  "component---src-pages-what-we-offer-js": () => import("./../../../src/pages/what-we-offer.js" /* webpackChunkName: "component---src-pages-what-we-offer-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

