import React from 'react'
import styled from 'styled-components'

export const Span = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.font.manrope};
  line-height: ${({ theme }) => theme.lineHeight.xl};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.secondary)};
  &:hover {
    text-decoration: ${(props) => (props.noHover ? 'none' : 'underline')};
    color: ${(props) => (props.noHover ? props.theme.colors.secundary : props.theme.colors.primary)};
  }
`

const LinkText = ({
  children,
  ...restProps
}) => (
  <Span
    {...restProps}
  >
    {children}
  </Span>
)

export default LinkText
