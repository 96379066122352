/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest, changelanguages } from '../helpers'

const OfficesContext = createContext('')
// function compare(a, b) {
//   if (a.order > b.order) {
//     return -1
//   }
//   if (a.order < b.order) {
//     return 1
//   }
//   return 0
// }

const getContinents = (offices) => {
  const newArray = _.map(offices, (o) => ({
    name: _.get(o, 'continent.name'),
    slug: _.get(o, 'continent.slug'),
    order: _.get(o, 'continent.slug') === 'asia' ? 0 : _.get(o, 'continent.slug') === 'america' ? 1 : 100,
  }))
  return _.uniqBy(newArray, (e) => e.slug).sort((a, b) => (a.order - b.order))
}
const OfficesProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [offices, setOffices] = useState([])
  const [loading, setLoading] = useState(false)
  const [continents, setContinents] = useState([])

  const getOffices = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'offices',
      params: {
        lang: changelanguages(i18n.language),
      },
    }).then((resp) => {
      setOffices(resp.data)
      setLoading(false)
      setContinents(getContinents(resp.data))
    })
  }, [i18n.language])

  useEffect(() => {
    getOffices()
  }, [i18n.language])

  const values = {
    loading,
    offices,
    continents,

  }

  return (
    <OfficesContext.Provider value={values}>
      {children}
    </OfficesContext.Provider>
  )
}

const useOffices = () => {
  const context = useContext(OfficesContext)

  if (!context) { throw new Error('useLocale must be used within an CategoriesProvider') }

  return context
}

export { OfficesProvider, useOffices }
