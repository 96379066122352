/* eslint-disable no-confusing-arrow */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import DropdownArrow from '../../assets/icons/TAM-icon-dropdown.svg'
import Wrapper from '../page-elements/wrapper'
import { device } from '../../theme/device'
import './accordion-style.scss'

const WrapperAccordionStyle = styled.div`
  min-height: 50px;
  width: 100%;
  position: relative;
  display: block;
`
const WrapperArrow = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  svg {
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : ' rotate(0deg)')};
  }
`
const WrapperContent = styled.div`
  height: 0;
  width: 100%;
  padding: 0 0 25px 0;
  background-color: transparent;
  color: black;
  font-size: 14px;
  text-align: center;
  position: relative;
  text-align: left;
  transition: all 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);

  ${(props) => props.isOpen
    && css`
      margin-top: 0px;
      height: auto;
      transition: all 350ms cubic-bezier(0.08, 1.09, 0.32, 1.275);
    `};
  @media ${device.desktop} {
    width: 420px;
  }
`
const TextContent = styled.div`
  padding: ${(props) => (props.noLink) ? '6px 0 15px ' : '10px 0'};
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  line-height: ${({ theme }) => theme.lineHeight.xl};
  font-size: ${({ theme, noLink }) => noLink ? theme.fontSize.sm : theme.fontSize.base};
  font-family: ${({ theme }) => theme.font.manrope};
  color: ${(props) => (props.color
    ? props.theme.colors[props.color]
    : props.theme.colors.secondary)};
  ${(props) => props.isOpen
    && css`
      visibility: visible;
      opacity: 1;
    `};
  @media ${device.tablet} {
    padding: ${(props) => (props.noLink) ? '6px 0 15px ' : '5px 0'};
  }
`

const TextSubtitle = styled.p`
visibility: hidden;
opacity: 0;
overflow: auto;
margin-top: 8px;
line-height: ${({ theme }) => theme.lineHeight.base};
font-size: ${({ theme }) => theme.fontSize.xs};
font-family: ${({ theme }) => theme.font.manrope};
color: ${(props) => (props.color
    ? props.theme.colors[props.color]
    : props.theme.colors.secondary)};
${(props) => props.isOpen
  && css`
    visibility: visible;
    opacity: 1;s
  `};
`

const TitleAccordionStyle = styled.p`
  line-height: ${({ theme }) => theme.lineHeight.xl};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-family: ${({ theme }) => theme.font.manrope};
  color: ${(props) => (props.color
    ? props.theme.colors[props.color]
    : props.theme.colors.secondary)};
  z-index: 2000;
  font-weight: 600;
`

const sections = (accordionItems, click, t) => _.map(accordionItems, (i, idx) => {
  if (accordionItems.length === 0) return null
  return (
    <WrapperAccordionStyle key={i.title + idx}>
      <Wrapper
        flexDirectionM="row"
        justifyContentM="space-between"
        displayM="block"
        heightM="30px"
        backgroundColor="#fff"
        zIndex="2000"
      >
        <Link to={`${i.src}/`}>
          <TitleAccordionStyle>
            {t(`menuMobile.${i.input}`)}
          </TitleAccordionStyle>
        </Link>
        {
          !i.isOpenByDefault && (
            <WrapperArrow
              onClick={() => click(accordionItems.indexOf(i))}
              isOpen={i.open}
            >
              <DropdownArrow width={30} />
            </WrapperArrow>

          )
        }
      </Wrapper>
      <WrapperContent WrapperContent isOpen={i.isOpenByDefault ? true : i.open}>
        {
          i.children && _.map(i.children, (child, index) => {
            const excerpt = _.get(child, 'excerpt.rendered')
            const text = _.get(child, 'text.rendered', child.text)
            const content = text || excerpt
            return (
              !_.isEmpty(child.src) ? (
                <Link to={`${child.src}/`} key={index}>
                  <TextContent isOpen={i.open} >{ text || t(child.title)}</TextContent>
                </Link>

              ) : (
                <React.Fragment key={index}>
                  {
                    i.isNews ? (
                      <Link to={`/newsroom/${i.slug}/`} >
                        <TextSubtitle isOpen={i.isOpenByDefault ? true : i.open}>
                          {_.get(child, 'title.rendered', t(child.title))}
                        </TextSubtitle>
                      </Link>
                    ) : (
                      <TextSubtitle isOpen={i.isOpenByDefault ? true : i.open}>{_.get(child, 'title.rendered', t(child.title))}</TextSubtitle>
                    )
                  }
                  <TextContent noLink isOpen={i.isOpenByDefault ? true : i.open} >
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </TextContent>
                </React.Fragment>
              )
            )
          })
        }
      </WrapperContent>
    </WrapperAccordionStyle>
  )
})

const Accordion = ({
  data, extradata, t, i18n,
}) => {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const [accordionItems, setAccordionItems] = useState([])
  useEffect(() => {
    const accordion = []
    data.forEach((i) => {
      accordion.push({
        title: i.title,
        input: i.input,
        src: i.src,
        children: i.children || extradata,
        isNews: (i.title === 'Newsroom'),
        isOpenByDefault: i.isOpenByDefault,
        open: false,
      })
    })
    setAccordionItems(accordion)
  }, [data, url, i18n.language])

  function click(i) {
    // const newAccordion = accordionItems.slice()
    if (accordionItems[i].open) {
      const newAccordion = accordionItems.map((accordionItem) => ({
        ...accordionItem,
        open: false,
      }))
      setAccordionItems(newAccordion)
    } else {
      const newAccordion = accordionItems.map((accordionItem) => ({
        ...accordionItem,
        open: !!accordionItem[i],
      }))
      newAccordion[i].open = !newAccordion[i].open
      setAccordionItems(newAccordion)
    }
  }

  return <div className="accordion">{sections(accordionItems, click, t)}</div>
}

export default Accordion
