import React from 'react'
import styled from 'styled-components'
import { device } from '../../theme/device'

const Wrapper = styled.div`
  
  width: ${(props) => (props.auto ? 'auto' : '100%')};
  max-width: ${(props) => (props.widthM ? props.widthM : '100%')};
  display: ${(props) => (props.displayM ? props.displayM : 'flex')};
  flex-direction: ${(props) => (props.flexDirectionM ? props.flexDirectionM : 'column')};
  align-items: ${(props) => (props.alignItemsM ? props.alignItemsM : 'center')};
  justify-content: ${(props) => (props.justifyContentM ? props.justifyContentM : 'center')};
  padding: ${(props) => (props.paddingM ? props.paddingM : 0)};
  margin: ${(props) => (props.marginM ? props.marginM : 0)};
  height: ${(props) => (props.heightM ? props.heightM : 'auto')};
  background-color: ${(props) => props.theme.colors[props.backgroundColor]};
  position: relative;
  box-sizing: border-box;
  z-index: ${(props) => (props.zIndex ? props.zIndex : '0')};
  
  @media ${device.desktop} {
    width: ${(props) => (props.auto ? 'auto' : '100%')};
    max-width: ${(props) => (props.widthD ? props.widthD : props.widthM)};
    display: ${(props) => (props.displayD ? props.displayD : props.displayM)};
    flex-direction: ${(props) => (props.flexDirectionD ? props.flexDirectionD : 'row')};
    align-items: ${(props) => (props.alignItemsD ? props.alignItemsD : props.alignItemsM)};
    justify-content: ${(props) => (props.justifyContentD ? props.justifyContentD : props.justifyContentM)};
    padding: ${(props) => (props.paddingD ? props.paddingD : 0)};
    margin: ${(props) => (props.marginD ? props.marginD : '0 auto')};
    height: ${(props) => (props.heightD ? props.heightD : props.heightM)};
  }
  @media ${device.large} {
    padding: ${(props) => (props.paddingL ? props.paddingL : props.paddingD)};
    max-width: ${(props) => (props.widthL ? props.widthL : props.widthD)};
    
  }
`

export default Wrapper

const WrapperFixed = styled((props) => <Wrapper {...props} />)`
  position: fixed;
  top: ${(props) => (props.top || 0)};
  left: ${(props) => (props.left || 0)};

`

export {
  WrapperFixed,
}
