/* eslint-disable no-param-reassign */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withTrans } from '../../i18n/withTrans'
import Wrapper from '../page-elements/wrapper'
import LinkBlock from '../LinkBlock'
import LinkText from '../LinkText'
import LinkRow from '../LinkRow'
import { PreFooter } from './components'
import { useNewsroom } from '../../hooks/use-news'
import { usePartners } from '../../hooks/use-partners'
import { useOffices } from '../../hooks/use-offices'
import SocialMedia from '../social-media'
import { menu } from '../../data/data'
import { getHeadquarter } from '../../helpers/sort-by-country'

const Span = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  margin: 0 5px;

`

const Footer = ({ t, socialMedia }) => {
  const [dataFlow, setDataFlow] = useState([])
  const [headquarter, setHeadquarter] = useState({})
  const { partherOnFooter } = usePartners()
  const { lastNews } = useNewsroom()
  const { offices } = useOffices()
  const objs = _.reduce(socialMedia, (result, value, key) => {
    (result).push({
      name: key,
      link: value,
      size: 22,
      color: '#E20613',
    })

    return result
  }, [])

  useEffect(() => {
    setHeadquarter(getHeadquarter(offices))
  }, [offices])

  useEffect(() => {
    const newData = _.map(menu, (item) => {
      if (item.src === '/our-clients/') {
        return {
          ...item,
          children: partherOnFooter,
        }
      }
      if (item.src === '/connect-with-us/') {
        return {
          ...item,
          children: [
            {
              title: t('footer.us.headquarter'),
              text: _.get(headquarter, 'acf.office_section.full_address'),
              noHover: true,
            },
            {
              title: t('footer.us.email'),
              text: _.get(headquarter, 'acf.office_section.email'),
              link: {
                href: `mailto:${_.get(headquarter, 'acf.office_section.email')}`,
                sameTab: true,
              },
            },
            {
              title: t('footer.us.phone'),
              text: _.get(headquarter, 'acf.office_section.phone_no'),
              link: {
                href: `tel:${_.get(headquarter, 'acf.office_section.phone_no')}`,
                sameTab: true,
              },
            },
          ],
        }
      }
      return item
    })
    setDataFlow(newData)
  }, [partherOnFooter, headquarter])

  return (
    <Wrapper
      displayM="none"
      displayD="flex"
      marginD="160px 0 40px 0">
      <Wrapper
        displayM="block"
        widthD="1920px"
        justifyContentD="space-between"

      >
        <Wrapper>
          <PreFooter />
        </Wrapper>
        <Wrapper
          flexDirectionD="row"
          widthD="1240px"
          paddingD="0 15px"
          alignItemsM="flex-start"
        >
          {
            _.map(dataFlow, (item, idx) => (
              <LinkBlock
                key={idx + Math.random()}
                item={item}
                lastNews={lastNews}
                t={t}
              />
            ))
          }
        </Wrapper>
        <Wrapper
          widthD="1240px"
          marginD="16px auto 0"
          alignItemsM="flex-start">
          <SocialMedia data={objs } />
        </Wrapper>
        <div className="separator">

        </div>
        <Wrapper
          widthD="1240px"
          paddingD="0 15px"
          justifyContentM="space-between"
          flexDirectionD="row">
          <Wrapper
            style={{ width: 'auto' }}
            marginD="0"
          >
            <LinkText
              style={{
                fontSize: '12px',
              }}
              noHover>
              {t('footer.copyright')}
            </LinkText>
          </Wrapper>
          <Wrapper
            flexDirectionD="row"
            style={{ width: 'auto' }}
            marginD="0"
          >
            <LinkRow
              link="/terms-conditions"
              gatsbyLink
              margin="0"
            >
              <LinkText
                style={{
                  fontSize: '12px',
                }}
              >
                {t('footer.terms')}
              </LinkText>
            </LinkRow>
            <Span > | </Span>
            <LinkRow
              link="/privacy-policy"
              gatsbyLink
              margin="0"
            >
              <LinkText
                style={{
                  fontSize: '12px',
                }}>
                {t('footer.privacy')}
              </LinkText>
            </LinkRow>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default withTrans(Footer)
