import _ from 'lodash'

import React from 'react'
import { navigate } from 'gatsby'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from '../button'
import LinkRow from '../LinkRow'
import Wrapper, { WrapperFixed } from '../page-elements/wrapper'

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-family: ${({ theme }) => theme.font.manrope};
  line-height: ${({ theme }) => theme.lineHeight.xl};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.colors.secondary};
`

export const Subtitle = styled(Title)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.font.manrope};
  line-height: ${({ theme }) => theme.lineHeight.xl};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  display: block;
  margin-top: 20px;
  padding-left: 20px;
`

const WrapperLink = styled.div`
  margin-bottom: 40px;
`
const WrapperLanguages = styled.div`
  margin-top: 50px;
`
const ListLanguages = styled.ul`
  display: flex;
`

const TextLanguages = styled.ul`
  margin-right: 16px;
  font-size: ${({ theme }) => theme.fontSize.base};
  font-family: ${({ theme }) => theme.font.manrope};
  line-height: ${({ theme }) => theme.lineHeight.xl};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: #7b7b7b;
  text-transform: uppercase;
`

const MenuMobile = ({
  onToggleMenu,
  isOpenMenu,
  t,
  i18n,
}) => {
  const options = [
    { value: 'en', label: 'EN' },
    { value: 'zh-TW', label: '繁體' },
    { value: 'zh-CN', label: '简体' },
  ]
  const onPressButton = (e) => {
    e.preventDefault()
    onToggleMenu()
    navigate('/connect-with-us/')
  }

  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    onToggleMenu()
  }

  return (
    <React.Fragment>
      {isOpenMenu && (
        <WrapperFixed
          widthM="100%"
          heightM="100vh"
          justifyContentM="flex-start"
          top="73px"
          style={{
            backgroundColor: 'rgba(0,0,0,50%)',

          }}
        >
          <Wrapper
            backgroungColor="#fff"
            paddingM="40px 25px"
            justifyContentM="flex-start"
            alignItemsM="flex-start"
            backgroundColor="white"
          >
            <WrapperLink>
              <LinkRow
                gatsbyLink
                link="/who-we-are/"
                onClick={onToggleMenu}
              >
                <Title
                  style={{
                    textTransform: 'capitalize',
                  }}>
                  {t('menu.are')}
                </Title>
              </LinkRow>
            </WrapperLink>
            <WrapperLink>
              <LinkRow
                gatsbyLink
                link="/what-we-offer/"
                onClick={onToggleMenu}
              >
                <Title style={{
                  textTransform: 'capitalize',
                }}>
                  {t('menu.offer')}
                </Title>
              </LinkRow>
              <LinkRow
                gatsbyLink
                link="/what-we-offer/cargo/"
                onClick={onToggleMenu}
              >
                <Subtitle style={{
                  textTransform: 'capitalize',
                }}>
                  {t('menu.cargo')}
                </Subtitle>
              </LinkRow>
              <LinkRow
                gatsbyLink
                link="/what-we-offer/passenger/"
                onClick={onToggleMenu}
              >
                <Subtitle style={{
                  textTransform: 'capitalize',
                }}>
                  {t('menu.passenger')}
                </Subtitle>
              </LinkRow>
            </WrapperLink>
            <WrapperLink>
              <LinkRow
                gatsbyLink
                link="/our-clients/"
                onClick={onToggleMenu}
              >
                <Title
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  {t('menu.server')}
                </Title>
              </LinkRow>
            </WrapperLink>

            <Button
              active
              style={{
                textTransform: 'capitalize',
              }}
              onClick={onPressButton}>
              {t('menu.us')}
            </Button>

            {/* <WrapperLanguages>
              <ListLanguages>
                {
                  _.map(options, (opt) => (
                    <TextLanguages
                      key={opt.value}
                      onClick={() => onChangeLanguage(opt.value)}>
                      {opt.label}
                    </TextLanguages>))
                }
              </ListLanguages>
            </WrapperLanguages> */}
          </Wrapper>
        </WrapperFixed>

      )
      }
    </React.Fragment>

  )
}

export default withTranslation()(MenuMobile)
