/* eslint-disable no-undef */
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { withTrans } from '../../i18n/withTrans'
import FooterDesktop from '../footer/footer-desktop'
import FooterMobile from '../footer/footer-mobile'
import HeaderDesktop from '../header/header-desktop'
import HeaderMobile from '../header/header-mobile'
import MenuMobile from '../menu-mobile/menu-mobile'
import Scroll from '../scrollToTop'
import { makeRequest, useWindowDimensions, changelanguages } from '../../helpers'

import './layout.scss'

const StyledMain = styled.main`
  min-height: 500px;
;
`

const Layout = ({
  children,
  t,
  i18n,
  location,
}) => {
  const [showToTop, setShowToTop] = useState(true)
  const [isOpenMenu, setisOpenMenu] = useState(false)
  const [socialMedia, setSocialMedia] = useState([])
  const onToggleMenu = () => {
    setisOpenMenu(!isOpenMenu)
  }
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const breakpoints = useBreakpoint()
  const isWhatWeOffer = _.get(location, 'pathname') === '/what-we-offer/' || _.get(location, 'pathname') === '/what-we-offer'

  const getSocialMedia = useCallback(async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      custom: true,
      headers,
      endPoint: 'social-media',
      params: {
        lang: changelanguages(i18n.language),
      },
    }).then((resp) => {
      setSocialMedia(resp.data)
    })
  }, [])

  useEffect(() => {
    if (_.includes(location, 'contact')) {
      setShowToTop(false)
    } else {
      setShowToTop(true)
    }
  }, [location])

  useEffect(() => {
    getSocialMedia()
  }, [i18n.language])

  return (
    <div className="global-wrapper">
      <Helmet
        bodyAttributes={{
          class: isOpenMenu ? 'is-open-menu' : '',
        }}
      />
      <Helmet>
        <script
          type="text/javascript"
          charset="UTF-8"
          async={true}
          defer={true}
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLEMAP_ID}`}
        />
      </Helmet>
      <HeaderDesktop t={t} i18n={i18n} />
      <HeaderMobile
        onToggleMenu={onToggleMenu}
        isOpenMenu={isOpenMenu}
      />
      <StyledMain>{children}</StyledMain>
      {!isWhatWeOffer && <FooterDesktop socialMedia={socialMedia} t={t} i18n={i18n} />}
      {!isWhatWeOffer && <FooterMobile socialMedia={socialMedia} t={t} i18n={i18n} />}
      {width < 1024 && <MenuMobile i18n={i18n} onToggleMenu={onToggleMenu} isOpenMenu={isOpenMenu} />}
      {showToTop && <Scroll showBelow={2000} />}
    </div>
  )
}

export default withTrans(Layout)
