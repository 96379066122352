import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import Wrapper from '../page-elements/wrapper'
import LinkRow from '../LinkRow'
import LinkTitle from '../LinkTitle'
import LinkSubTitle from '../LinkSubTitle'
import LinkText from '../LinkText'

const LinkBlock = ({
  item,
  lastNews,
  t,
}) => (
  <Wrapper
    alignItemsM="flex-start"
    justifyContentM="flex-start"
    flexDirectionD="column"
    alignItemsD="flex-start"
  >
    {
      item.title ? (
        <LinkRow
          link={`${item.src}`}
          gatsbyLink
        >
          <LinkTitle text={t(item.title)}/>
        </LinkRow>
      ) : (
        <p style={{ height: '20px' }}> </p>
      )
    }
    <Wrapper
      widthD="250px"
      alignItemsM="flex-start"
      justifyContentM="flex-start"
      flexDirectionD="column"
      marginD="6px 0 0 0"
    >
      {
        item.children ? item.children.map((link, idx) => {
          if (link.anchor) {
            return <LinkRow
              className="anchor-footer"
              key={idx}
              gatsbyLink
              link={link.src}>
              {
                link.title && (
                  <LinkSubTitle text={t(link.title)}/>)
              }
              <LinkText noHover={link.noHover}>
                {t(link.text)}
              </LinkText>
            </LinkRow>
          }
          if (!_.isEmpty(link.link)) {
            return <LinkRow
              key={idx}
              link={link.link}
              style= {{
                marginTop: '23px',
              }}
            >
              {
                link.title && (
                  <LinkSubTitle text={t(link.title)}/>)
              }
              <LinkText noHover={link.noHover}>
                {link.text}
              </LinkText>
            </LinkRow>
          }
          if (!_.isEmpty(link.src)) {
            return <LinkRow
              key={idx}
              link={link.src}
              gatsbyLink

            >
              {
                link.title && (
                  <LinkSubTitle text={t(link.title)}/>)
              }
              <LinkText noHover={link.noHover}>
                {link.noAirline ? t(link.text) : link.text}
              </LinkText>
            </LinkRow>
          }
          return (
            <React.Fragment key={idx}>
              {
                link.title && (<LinkSubTitle text={link.title}/>)
              }
              <LinkText noHover={link.noHover}>
                {link.text}
              </LinkText>

            </React.Fragment>
          )
        })
          : (
            <div style={{
              paddingRight: '20px',
            }}>
              {
                lastNews && _.map(lastNews, (news) => (
                  <LinkRow
                    key={news.date}
                    link={`/newsroom/${news.slug}`}
                    gatsbyLink
                    style={{
                      display: 'block',
                      marginBottom: '23px',
                    }}>
                    <div>
                      <LinkSubTitle text={moment(news.date).format('MMM DD, YYYY')}/>
                    </div>
                    <LinkText >{_.get(news, 'title.rendered')}</LinkText>
                  </LinkRow>
                ))
              }

            </div>
          )
      }
    </Wrapper>
  </Wrapper>

)

export default LinkBlock
