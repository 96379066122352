import styled from 'styled-components'
import { device } from '../../theme/device'

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize['3xl']};
  font-family: ${({ theme }) => theme.font.manrope};
  line-height: ${({ theme }) => theme.lineHeight['4xll']};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.secondary)};
  @media ${device.desktop} {
    font-size: ${({ theme }) => theme.fontSize['4xl']};
    line-height: ${({ theme }) => theme.lineHeight['6xl']};
  }
`
