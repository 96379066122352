import React from 'react'
import styled from 'styled-components'

export const Title = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.font.manrope};
  line-height: ${({ theme }) => theme.lineHeight.xl};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.colors.secondary};
`

const LinkTitle = ({ text }) => (
  <Title>
    {text}
  </Title>
)

export default LinkTitle
