import styled from 'styled-components'
import { device } from '../../theme/device'

const Button = styled.button`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-family: ${({ theme }) => theme.font.gorditaM};
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.primary)};
  background-color: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.white)};
  border: ${({ theme, active }) => (active ? 'none' : `1px solid ${theme.colors.primary}`)};
  width: ${(props) => (props.widthM ? props.widthM : '100%')};
  padding: ${(props) => (props.paddingM ? props.paddingM : 0)};
  margin: ${(props) => (props.marginM ? props.marginM : 0)};
  transition: all 1s;
  height: 50px;
 
  @media ${device.tablet} {
    width: ${(props) => (props.widthD ? props.widthD : '100%')};
    height: 50px;
    padding: ${(props) => (props.paddingD ? props.paddingD : 0)};
    margin: ${(props) => (props.marginD ? props.marginD : 0)};
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      color: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.white)};
      background-color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.primary)};
      border: ${({ theme, active }) => (active ? `1px solid ${theme.colors.primary}` : 'none')};
    }
  }
`

export default Button
