import React from 'react'
import { withTheme } from 'styled-components'
import { Link } from 'gatsby'
import Wrapper from '../page-elements/wrapper'
import Logo from '../../assets/icons/TAM-logo-secondary.svg'
import IconBurger from '../../assets/icons/TAM-icon-burgermenu.svg'
import IconBurgerClose from '../../assets/icons/TAM-icon-burgermenu-close.svg'
import IconLinkeding from '../../assets/icons/TAM-icon-linkedin.svg'
import LinkRow from '../LinkRow'
import LinkText from '../LinkText'

const HeaderMobile = ({
  theme,
  onToggleMenu,
  isOpenMenu,
}) => (
  <Wrapper
    displayM="flex"
    displayD="none"
    flexDirectionM="row"
    justifyContentM="space-between"
    style={{
      border: '1px solid #E7E7E7',
      top: 0,
      left: 0,
      position: 'sticky',
      zIndex: 1000000000,
      backgroundColor: 'white',
    }}
  >
    <Wrapper widthM="58px" marginM="11px 25px">
      <Link to="/">
        <Logo width="56" fill={theme.colors.primary} />
      </Link>
    </Wrapper>

    <Wrapper auto flexDirectionM="row" marginM="11px 25px">
      <Wrapper widthM="35px" >
        <a href={'https://hk.linkedin.com/company/tamwingkun'} margin="0">
          <LinkText>
            <IconLinkeding width="30" color={theme.colors.secondary} />
          </LinkText>
        </a>
      </Wrapper>
      <Wrapper widthM="35px" onClick={onToggleMenu}>
        {
          isOpenMenu ? (
            <IconBurgerClose width="20" color={theme.colors.secondary} style={{ margin: '0 6px 0 4px ' }} />
          ) : (
            <IconBurger width="30" color={theme.colors.secondary} />
          )
        }
      </Wrapper>
    </Wrapper>
  </Wrapper>

)

export default withTheme(HeaderMobile)
