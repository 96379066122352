import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from 'styled-components'
import { PartherProvider } from '../hooks/use-partners'
import { OfficesProvider } from '../hooks/use-offices'
import { NewsroomProvider } from '../hooks/use-news'
import i18next from '../i18n/config'
import { GlobalStyle, theme } from '../theme'

const wrapRootElement = ({ element }) => (
  <I18nextProvider i18n={i18next}>
    <ThemeProvider theme={theme}>
      <PartherProvider>
        <OfficesProvider>
          <NewsroomProvider>
            <GlobalStyle />
            {element}
          </NewsroomProvider>
        </OfficesProvider>
      </PartherProvider>
    </ThemeProvider>
  </I18nextProvider>
)

export default wrapRootElement
