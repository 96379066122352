import { useState, useEffect } from 'react'

const isBrowser = typeof window !== 'undefined'

function getWindowDimensions() {
  if (isBrowser) {
    const width = isBrowser ? window.innerWidth : 0
    const height = isBrowser ? window.innerHeight : 0
    return {
      width,
      height,
    }
  }
}

export default function useWindowDimensions() {
  if (typeof window === 'undefined') return

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    if (isBrowser) {
      window.addEventListener('resize', handleResize)
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return windowDimensions
}
