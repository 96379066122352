import styled from 'styled-components'
import { device } from '../../theme/device'

export const Subtitle = styled.h4`
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.font.manrope};
  line-height: ${({ theme }) => theme.lineHeight['2xl']};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.primary)};
  @media ${device.desktop} {
    font-size: ${({ theme }) => theme.fontSize.xss};
  }
`
