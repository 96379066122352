import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import './dropdown-styles.scss'
import LinkRow from '../LinkRow'
import IconDropdown from '../../assets/icons/TAM-icon-dropdown.svg'

export const LinkSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-family: ${({ theme }) => theme.font.manrope};
  font-weight: 600;
`

const Dropdown = ({ title, options }) => {
  const [showMenu, setShowMenu] = useState(false)
  const ref = useRef(null)

  function showMenuFun(event) {
    event.preventDefault()

    setShowMenu(true)
  }

  function closeMenu() {
    setShowMenu(false)
  }

  useEffect(() => {
    if (showMenu) {
      window.addEventListener('click', closeMenu)
    }
    return () => {
      window.removeEventListener('click', closeMenu)
    }
  }, [showMenu])

  return (
    <div
      className="dropdown-container"
      onMouseEnter={showMenuFun}
      onMouseLeave={closeMenu}>
      <LinkRow
        style= {{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        margin="0"
        gatsbyLink
        link="/what-we-offer/">
        <LinkSpan style= {{
          textTransform: 'capitalize',
          textDecoration: showMenu ? 'underline' : 'none',
          color: showMenu ? '#E20613' : 'inherit',

        }}>{title}</LinkSpan>
        {
          !showMenu
            ? <span onClick={showMenuFun} style={{ display: 'inline-block' }}>
              <IconDropdown width={18} color="#000" />
            </span>

            : <span style={{
              display: 'inline-block',
              transform: 'rotate(180deg)',
            }}>
              <IconDropdown width={18} color="#000" /></span>
        }
      </LinkRow>

      {
        showMenu
          ? (
            <div className="menu-shadow" onMouseLeave={closeMenu}>
              <div
                className="menu"
                ref={ref}
              >
                {
                  _.map(options, (option, idx) => (

                    <LinkRow key={idx} margin="0" gatsbyLink link={`${option.value}/`}>
                      <LinkSpan style= {{
                        textTransform: 'capitalize',
                        fontSize: '14px',
                      }}>{option.label}</LinkSpan>
                    </LinkRow>
                  ))
                }
              </div>
            </div>
          )
          : (
            null
          )
      }
    </div>
  )
}

export default Dropdown
