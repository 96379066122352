/* eslint-disable global-require */
import i18next from 'i18next'
// import detector from 'i18next-browser-languagedetector'
import Cache from 'i18next-localstorage-cache'

i18next
  // .use(detector)
  .use(Cache)
  .init({
    // fallbackLng: ['en', 'zh-CN', 'zh-TW'],
    fallbackLng: ['en'],
    resources: {
      // 'zh-CN': {
      //   translations: require('./locales/zh-cn/translation.json'),
      // },
      en: {
        translations: require('./locales/en/translation.json'),
      },
      // 'zh-TW': {
      //   translations: require('./locales/zh-tw/translation.json'),
      // },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    },
  })

i18next.languages = ['en']

export default i18next
