import React from 'react'
import styled from 'styled-components'

const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all .2s ease-in-out;
  cursor: pointer;
  width: 180px;
  height: 180px;
  &::before { 
    content: "";
    box-shadow: 0 20px 50px 0 rgba(22,41,52,0.2);
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    width: 180px;
    height: 180px;
    border-radius: 90px;
    transition: all .2s ease-in-out;
  }
  &:hover {
    &::before { 
      transform: scale(1.2);

    }
  }
`
const TextButton = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.gorditaM};

`

const ButtonWork = ({ onPress, children }) => (
  <WrapperButton onClick={onPress}>
    <TextButton>{children}</TextButton>
  </WrapperButton>
)

export default ButtonWork
